import TransparentWaiterIcon from '@/assets/svg/TransparentWaiter';
import { useAppSelector } from '@/redux/hooks';
import { Box, Button, IconButton, Typography } from '@mui/material';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import React, { useEffect, useRef, useState } from 'react';
import { useMain } from '@/contexts/main';
import { isMobile } from 'react-device-detect';
import ModalWaiter from '@/components/ModalWaiter';
import ModalInfo from '@/components/ModalInfo';
import { useRouter } from 'next/router';
import ModalFilter from '@/components/ModalFilter';
import FixedDepartments from '@/pages/cardapio/components/FixedDepartments';
import FixedDepartmentsDelivery from '@/components/DeliveryPage/ProductsPage/FixedDepartments';
import WaiterIcon from '@/assets/svg/Waiter';
import { p500 } from '@/styles/typography/fonts';
import { GiRoundStar } from 'react-icons/gi';
import RatingScreenCardapio from './components/ratingScreenCardapio';
import { IoIosArrowBack } from 'react-icons/io';

export default function TopAppBar() {
	const router = useRouter();
	const defaultRoute = router.route;
	const isPedidos = defaultRoute === '/pedidos';
	const { storageLogin } = useMain();
	const home = useAppSelector(state => state.home);
	const { responseLogin, loginFields, client } = useAppSelector(state => state.auth);
	const { list } = useAppSelector(state => state.home);
	const authDelivery = useAppSelector(state => state.authDelivery);
	const authNormal = useAppSelector(state => state.auth);
	const auth = isPedidos ? authDelivery : authNormal;
	const personalData = responseLogin || storageLogin;
	// const showWaiter = !!(auth.client?.config_cliente?.chamada_garcom && loginFields && !loginFields?.config_qrcode?.pre_pago && personalData && isMobile);

	const [openWaiter, setOpenWaiter] = useState(false);
	const [openCategories, setOpenCategories] = useState(false);
	const [showSnack, setShowSnack] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');
	const [openFilter, setOpenFilter] = useState(false);
	const [value, setValue] = useState<number | undefined>(undefined);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [ratingScreenOpen, setRatingScreenOpen] = useState(false); // Novo estado para o RatingScreen

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleScroll = () => {
		const position = window.pageYOffset;
		const element = document.querySelectorAll('.productContainer');
		let currentId;

		element.forEach(el => {
			const id_depto = el.getAttribute('id');

			if (id_depto) {
				const elemento_id = document.getElementById(id_depto?.toString());

				if (elemento_id) {
					const offset = elemento_id.getBoundingClientRect().top;

					if (offset < 120) {
						currentId = id_depto;
					}
				}
			}
		});

		if (currentId) {
			setValue(+currentId);
		}

		setScrollPosition(position);
	};

	const scrollDown = (id: number) => {
		const element = document.getElementById(id.toString());

		if (element !== null) {
			let pos = element.offsetTop - 80;

			window.scrollTo({ top: pos, behavior: 'smooth' });
		}
	};

	function handleCloseWaiter() {
		setOpenWaiter(false);
	}

	function handleModal(state: boolean) {
		setShowSnack(state);
	}

	const handleCategories = () => {
		setOpenCategories(prev => !prev);
	};

	const openNotifications = () => {
		setSnackMsg('Notificação da API');
		setShowSnack(true);
	};

	useEffect(() => {
		if (home.responseWaiter) {
			if (home.responseWaiter.mensagem) {
				setSnackMsg(home.responseWaiter.mensagem);
				setShowSnack(true);
			}

			if (home.responseWaiter.retorno) {
				handleCloseWaiter();
				setSnackMsg('Garçom chamado, por favor aguarde!');
				setShowSnack(true);
			}
		}
	}, [home.responseWaiter]);



	return (
		<Box className="appBarLayout">
			<Box sx={{ flexGrow: 1 }} id="appBar">
				<Box className="gridLeftSpace" >

					{list?.tipo === 'M' || list?.conteudo  && <div className="headerBottomSheet" style={{ paddingLeft: '0vw' }}>
						<Button variant="contained" className="btn" style={{ backgroundColor: '#' + client?.config_cliente?.cor }} onClick={() => router.push('/')} >
							<IoIosArrowBack style={{ fontSize: '1.5rem' }} />
						</Button>
					</div> }

					{auth?.client?.config_cliente?.titulo_mobile}
				
						<IconButton className="appBarIcons" onClick={() => setOpenFilter(true)}>
							<TuneOutlinedIcon sx={{ fontSize: '30px' }} />
						</IconButton>
					

					{auth.client?.config_cliente?.chamada_garcom && (
						<IconButton
							className="pr-2 appBarIcons"
							onClick={() => {
								setOpenWaiter(true);
							}}>
							<WaiterIcon />
						</IconButton>
					)}

					{/* {!showWaiter && (
						<IconButton
							className="pr-2 appBarIcons"
							onClick={() => {
								setOpenWaiter(true);
							}}>
							<WaiterIcon/>
						</IconButton>
					)} */}
				</Box>
				{auth?.client?.config_cliente?.tempo_entrega && <Box className="deliveryTime">{auth?.client?.config_cliente?.tempo_entrega}</Box>}
				<ModalWaiter open={openWaiter} handleClose={handleCloseWaiter} />
				<ModalInfo open={showSnack} handler={handleModal} message={snackMsg} />
				<ModalFilter open={openFilter} setOpen={setOpenFilter} />
				{/* {!isPedidos && <FixedDepartments currentValue={value} scrollDown={scrollDown} scrollPosition={scrollPosition} />} */}
				{isPedidos && <FixedDepartmentsDelivery currentValue={value} scrollDown={scrollDown} scrollPosition={scrollPosition} />}
			</Box>
			<RatingScreenCardapio open={ratingScreenOpen} setOpen={setRatingScreenOpen} /> {/* Passando open e setOpen */}
		</Box>
	);
}

import { Button, Modal } from '@mui/material';
import React from 'react';

type CompType = {
  open: boolean;
  title: string;
  subtitle?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export default function ModalSuccess({
  open,
  title,
  subtitle,
  onClick,
}: CompType) {
  return (
    <Modal
      open={open}
      id="modalPix"
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      disableScrollLock
    >
      <div className="pixContent">
        <div>
          <p className="statusTitle">TUDO CERTO...</p>
          <br />

          <p className="pixTitle">{title}</p>
          {subtitle && <p className="pixDesc text-center">{subtitle}</p>}
          <br />
          <Button variant="contained" className="btnPix" onClick={onClick}>
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
}

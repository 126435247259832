import { Button, Modal, TextField } from '@mui/material';
import React from 'react';

type Props = {
	open: boolean;
	handler: (state: boolean) => void;
	message: string;
	change: string;
	handleChange: Function;
};

export default function ModalMoney({ open, handler, message, change, handleChange }: Props) {
	return (
		<Modal open={open} id="modalPix" disablePortal disableEnforceFocus disableAutoFocus disableScrollLock style={{ zIndex: 999 }}>
			<div className="pixContent">
				<div>
					<br />
					<p className="pixTitle">{message}</p>
					<br />
					<TextField
						label="Quantidade para Troco"
						fullWidth
						value={change}
						onChange={(ev) => {
							handleChange(ev.target.value);
						}}
					/>
					<br />
					<br />
					<Button
						variant="contained"
						className="btnPix"
						onClick={() => {
							handler(false);
						}}>
						Ok
					</Button>
				</div>
			</div>
		</Modal>
	);
}

import ArrowIcon from '@/assets/svg/Arrow';
import { useCart } from '@/contexts/delivery/cart';
import { AddressModel } from '@/models/delivery/address.model';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { DeleteAddress } from '@/services/delivery/user';
import { Button, FormControl, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import React, { useState } from 'react';

type DeliveryProps = {
	address: number;
	addressTakeAway: number;
	setAddress: React.Dispatch<React.SetStateAction<number>>;
	setAddressTakeAway: React.Dispatch<React.SetStateAction<number>>;
	setEditAddress: React.Dispatch<React.SetStateAction<AddressModel | undefined>>;
	setOpenRegisterAddress: React.Dispatch<React.SetStateAction<boolean>>;
	setShowSnack: React.Dispatch<React.SetStateAction<boolean>>;
	setSnackMsg: React.Dispatch<React.SetStateAction<string>>;
	setOpenLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setMsgLoading: React.Dispatch<React.SetStateAction<string>>;
	setScheduleData: React.Dispatch<
		React.SetStateAction<{
			id: string;
			date: string;
		}>
	>;
	scheduleData: {
		id: string;
		date: string;
	};
	setShippingTax: React.Dispatch<React.SetStateAction<number>>;
};

export default function DeliveryComp({
	address,
	addressTakeAway,
	setAddress,
	setAddressTakeAway,
	setEditAddress,
	setOpenRegisterAddress,
	setShowSnack,
	setSnackMsg,
	setOpenLoading,
	setMsgLoading,
	scheduleData,
	setScheduleData,
	setShippingTax,
}: DeliveryProps) {
	const dispatch = useAppDispatch();
	const { client } = useAppSelector((state) => state.authDelivery);
	const { customer } = useAppSelector((state) => state.userDelivery);
	const { listTakeAway } = useAppSelector((state) => state.paymentDelivery);
	const { schedules } = useAppSelector((state) => state.orderDelivery);
	const [currentSchedule, setCurrentSchedule] = useState<
		{
			id: string;
			hora_inicial: string;
			hora_final: string;
		}[]
	>();

	function reverseString(str: string) {
		var splitString = str.split('-');
		var reverseArray = splitString.reverse();
		var joinArray = reverseArray.join('/');
		return joinArray;
	}

	return (
		<div className="delivery">
			<div className="deliveryHead">Selecione a forma de entrega</div>
			<div className="deliveryContent">
				{client?.config_cliente.tipo.includes('Entrega') && (
					<div className="deliveryItem" style={{ borderBottom: '1px solid var(--second-color)' }}>
						<p className="font-medium" style={{ color: 'var(--first-color)' }}>
							Endereço de entrega
						</p>
						<RadioGroup value={address}>
							<ul>
								{customer?.conteudo.enderecos &&
									customer?.conteudo.enderecos.map((address) => (
										<li key={address.id} className="deliveryListItem">
											<div
												className="itemContent"
												onClick={() => {
													if (address.valor_entrega === 'N') {
														setSnackMsg(`Entrega indisponível para o endereço selecionado, por favor selecione outra forma de entrega ou outro endereço!`);
														setShowSnack(true);
													} else {
														setShippingTax(+address.valor_entrega);
														setAddressTakeAway(0);
														setAddress(address.id);
													}
												}}>
												<p style={{ color: 'var(--first-color)' }}>
													{address.endereco}
													{', ' + address.numero}
													{address.complemento && ', ' + address.complemento}
													{', ' + address.bairro}
													{', ' + address.cidade}
													{', ' + address.estado}
												</p>
												<Radio value={address.id} style={{ color: 'var(--second-color)' }} />
											</div>
											<div className="flex gap-2 mt-1">
												<Button
													variant="text"
													className="itemContentBtn"
													style={{
														backgroundColor: 'var(--main-color)',
													}}
													onClick={() => {
														setEditAddress(address);
														setOpenRegisterAddress(true);
													}}>
													Editar
												</Button>
												<Button
													variant="text"
													className="itemContentBtn"
													style={{ backgroundColor: 'red' }}
													onClick={() => {
														setMsgLoading('Excluindo endereço...');
														setOpenLoading(true);
														(async () => {
															await dispatch(DeleteAddress(address.id));
															await setAddress(0);
														})();
													}}>
													Excluir
												</Button>
											</div>
										</li>
									))}
							</ul>
						</RadioGroup>
						<Button
							variant="text"
							className="addBtn"
							onClick={() => {
								setEditAddress(undefined);
								setOpenRegisterAddress(true);
							}}>
							+ Novo endereço
						</Button>
					</div>
				)}

				{client?.config_cliente.tipo.includes('Retirada') && (
					<div className="deliveryItem">
						<FormControl fullWidth>
							<InputLabel id="addressTakeAway-label">Endereço de Retirada</InputLabel>
							<Select
								labelId="addressTakeAway-label"
								id="addressTakeAway"
								value={addressTakeAway}
								label="Endereço de Retirada"
								onChange={(ev) => {
									setAddressTakeAway(+ev.target.value);
									setShippingTax(0);
									setAddress(0);
								}}>
								{listTakeAway?.conteudo.map((address) => (
									<MenuItem key={address.id} value={address.id} className="itemContent">
										{address.título}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<p style={{ color: 'var(--first-color)', marginTop: '10px', textAlign: 'justify' }}>
							{listTakeAway?.conteudo.filter((address) => +address.id === addressTakeAway)?.[0] && (
								<>{listTakeAway?.conteudo.filter((address) => +address.id === addressTakeAway)?.[0].endereco}</>
							)}
						</p>
					</div>
				)}

				{schedules?.retorno && schedules.conteudo && (
					<div className="deliveryItem" style={{ borderTop: '1px solid var(--second-color)' }}>
						<p className="font-medium " style={{ color: 'var(--first-color)' }}>
							Encomenda
						</p>
						<div className="blockSelect">
							<div className="selectIcon">
								<ArrowIcon />
							</div>
							<select
								className="select"
								value={scheduleData.date}
								onChange={(event) => {
									setScheduleData({
										...scheduleData,
										date: event.target.value,
									});
									schedules.conteudo.forEach((element) => {
										if (element.data === event.target.value) {
											setCurrentSchedule(element.horarios);
										}
									});
								}}>
								<option selected value="">
									Selecione uma data
								</option>
								{schedules.conteudo.map((item, index) => (
									<option value={item.data} key={index}>
										{reverseString(item.data)} - {item.dia}
									</option>
								))}
							</select>
						</div>
						<div className="blockSelect">
							<div className="selectIcon">
								<ArrowIcon />
							</div>
							<select
								className="select"
								value={scheduleData.id}
								disabled={!scheduleData.date}
								onChange={(event) =>
									setScheduleData({
										...scheduleData,
										id: event.target.value,
									})
								}>
								<option selected value="">
									Selecione um horário
								</option>
								{currentSchedule?.map((item, index) => (
									<option value={item.id} key={index}>
										{item.hora_inicial} - {item.hora_final}
									</option>
								))}
							</select>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

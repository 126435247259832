import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import ProductItem from '@/components/ProductItem';
import { useMain } from '@/contexts/delivery/main';
import { usePages } from '@/contexts/pages';
import { useAppSelector } from '@/redux/hooks';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResultSearchPage() {
  const { setCurrentProduct } = useMain();
  const { resultSearch } = useAppSelector((state) => state.searchDelivery);
  const { openResultSearch, setOpenResultSearch } = usePages();
  const { client } = useAppSelector((state) => state.authDelivery);

  function onDismiss() {
    setOpenResultSearch(false);
  }

  return (
    <Dialog
      fullScreen
      open={openResultSearch}
      onClose={onDismiss}
      TransitionComponent={Transition}
      id="resultSearch"
      scroll="paper"
      className="customDialog"
      disableScrollLock
    >
      <HeaderBottomSheet title="Resultado da Busca" onDismiss={onDismiss} />

      <section className="p-4">
        {resultSearch?.conteudo?.map((product) => (
          <ProductItem
            product={product}
            key={product.id}
            setCurrentProduct={setCurrentProduct}
            client={client}
          />
        ))}
      </section>
    </Dialog>
  );
}

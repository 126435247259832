import { useAppSelector } from '@/redux/hooks';
import { Button, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardIcon from '@/assets/svg/Card';
import PixIcon from '@/assets/svg/Pix';
import FormCard from '../FormCard';
import { useTheme } from 'next-themes';
import ModalMoney from '@/components/ModalMoney';
import CommonMask from '@/helpers/masks';
import CommonClears from '@/helpers/clears/common.clear';

const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

type PayProps = {
	changeAmount: string;
	handleChangeChanges: Function;
	address: number;
	setMethod: React.Dispatch<React.SetStateAction<string>>;
	method: string;
	setPayAtTheRestaurant: React.Dispatch<React.SetStateAction<string>>;
	payAtTheRestaurant: string;
	handleChange(value: string | number, fieldName: string, typeForm: 'personal' | 'card'): void;
	formCard: {
		number: string;
		cvc: string;
		month: string;
		year: string;
		parcelamento: string;
	};
	setFormCard: React.Dispatch<
		React.SetStateAction<{
			number: string;
			cvc: string;
			month: string;
			year: string;
			parcelamento: string;
		}>
	>;
	cardError: {
		number: string;
		cvc: string;
		month: string;
		year: string;
		parcelamento: string;
	};
};

export default function PayComp({
	address,
	setMethod,
	method,
	handleChange,
	formCard,
	setFormCard,
	changeAmount,
	cardError,
	payAtTheRestaurant,
	setPayAtTheRestaurant,
	handleChangeChanges,
}: PayProps) {
	const { client } = useAppSelector((state) => state.authDelivery);
	const [years, setYears] = useState<number[]>([]);
	const [modalMoney, setModalMoney] = useState(false);
	const { systemTheme, theme } = useTheme();
	const darkMode = systemTheme === 'dark' || theme === 'dark';

	function fillYears() {
		const currentYear = new Date().getFullYear();
		const newYears = [currentYear];

		for (let index = 1; index < 20; index++) {
			newYears.push(currentYear + index);
		}

		setYears(newYears);
	}

	useEffect(() => {
		fillYears();
	}, []);

	return (
		<div>
			<div
				className="py-3 px-4 font-medium text-white rounded-t-md"
				style={{
					backgroundColor: 'var(--main-color)',
				}}>
				Selecione a forma de pagamento
			</div>
			<div
				style={{
					border: '1px solid var(--second-color)',
					borderTop: 0,
				}}>
				{client && client.config_cliente.formas_pagamento_entrega_online && client.config_cliente.formas_pagamento_entrega_online.length ? (
					<div className="px-2 py-3" style={{ borderBottom: '1px solid var(--second-color)' }}>
						<p className="font-medium mb-2" style={{ color: 'var(--first-color)' }}>
							Pagar agora
						</p>

						<section>
							<RadioGroup value={method}>
								{client.config_cliente.formas_pagamento_entrega_online.map((methodToPay) => (
									<div key={methodToPay.id}>
										<div
											className="flex items-center justify-between gap-x-3 cursor-pointer"
											onClick={() => {
												setPayAtTheRestaurant('');
												setMethod(methodToPay.id);
											}}>
											<div className="flex items-center gap-x-3">
												<div className="methodsIcon ">
													{methodToPay.id == '2' ? (
														<CardIcon />
													) : (
														<div className="methodsIcon--pix">
															<PixIcon />
														</div>
													)}
												</div>
												<p className="methodsText">{methodToPay.nome}</p>
											</div>
											<Radio value={methodToPay.id} className="optionalRadio" />
										</div>
										{method === '2' && methodToPay.id === '2' && (
											<>
												<FormCard handleChange={handleChange} formCard={formCard} months={months} years={years} cardError={cardError} setFormCard={setFormCard} />
											</>
										)}
									</div>
								))}
							</RadioGroup>
						</section>
					</div>
				) : (
					<></>
				)}

				{client && client.config_cliente.formas_pagamento_entrega_loja && client.config_cliente.formas_pagamento_entrega_loja.length ? (
					<div className="px-2 py-3">
						<p className="font-medium" style={{ color: 'var(--first-color)' }}>
							{address === 0 ? 'Pagar na retirada' : 'Pagar na entrega'}
						</p>
						<p className="text-sm mb-2" style={{ color: 'var(--first-color)' }}>
							{address === 0 ? 'Realize o pagamento para o estabelecimento' : 'Realize o pagamento para entregador'}
						</p>

						<section>
							<RadioGroup value={payAtTheRestaurant}>
								{client.config_cliente.formas_pagamento_entrega_loja.map((methodToPay) => (
									<div key={methodToPay.id}>
										<div
											className="flex items-center justify-between gap-x-3 cursor-pointer"
											onClick={() => {
												setMethod('');
												setPayAtTheRestaurant(methodToPay.id);
											}}>
											<div className="flex items-center gap-x-3">
												{methodToPay.icone && (
													<div className={`methodsIcon ${darkMode && 'methodsIconWhite'}`}>
														<img src={methodToPay.icone} width="22" height="22" />
													</div>
												)}
												<p className="methodsText">{methodToPay.nome}</p>
												{methodToPay?.dinheiro && (
													<Button className="ml-24 btnTroco" onClick={() => setModalMoney(true)}>
														Precisa de Troco
													</Button>
												)}
											</div>
											<Radio value={methodToPay.id} className="optionalRadio" />
										</div>
									</div>
								))}
							</RadioGroup>
						</section>
						<RadioGroup value={method}>
							<ul></ul>
						</RadioGroup>
					</div>
				) : (
					<></>
				)}
			</div>
			<ModalMoney message="Para qual valor você precisará de troco?" open={modalMoney} handler={setModalMoney} change={changeAmount} handleChange={handleChangeChanges} />
		</div>
	);
}

import { useAppSelector } from '@/redux/hooks';
import { Button } from '@mui/material';
import React from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function BannerComp() {
  const { listBanners } = useAppSelector((state) => state.menuDelivery);

  return (
    <section className="banner">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        className="mySwiper"
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {listBanners?.conteudo?.map((banner) => (
          <SwiperSlide key={banner.id}>
            {banner.link ? (
              <a
                href={banner.link}
                target="_blank"
                className="bannerItem"
                style={{ backgroundImage: `url(${banner.imagem})` }}
              ></a>
            ) : (
              <Button
                onClick={() => null}
                className="bannerItem"
                style={{ backgroundImage: `url(${banner.imagem})` }}
              >
                {banner.descricao && (
                  <>
                    <div className="overlay"></div>
                    <p className="bannerName">{banner.descricao}</p>
                  </>
                )}
              </Button>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}

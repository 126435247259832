import { useCart } from '@/contexts/delivery/cart';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { PostCoupon } from '@/services/delivery/payment';
import { Button, Modal, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

type ModalType = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  coupon: string;
  setCoupon: React.Dispatch<React.SetStateAction<string>>;
};

export default function ModalCoupon({
  open,
  setOpen,
  coupon,
  setCoupon,
}: ModalType) {
  const dispatch = useAppDispatch();
  const { cart } = useCart();
  const { customer } = useAppSelector((state) => state.userDelivery);
  const [unitsId, setUnitsId] = useState<number[]>();

  useEffect(() => {
    if (open) {
      const newArrIds: number[] = [];
      cart.items.forEach((product) => {
        newArrIds.push(product.idUnity);
      });

      setUnitsId(newArrIds);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      disablePortal={true}
      disableEnforceFocus={true}
      id="modalCoupon"
    >
      <div className="modalContent " style={{ maxWidth: 320 }}>
        <p className="call">Possui um cupom de desconto?</p>
        <p className="title">Insira o cupom</p>
        <TextField
          value={coupon}
          className="input"
          fullWidth
          label="Cupom"
          variant="outlined"
          type="text"
          onChange={(event) => {
            const value = event.target.value;
            setCoupon(value);
          }}
        />
        <Button
          variant="contained"
          className="modalBtn"
          onClick={() => {
            if (coupon) {
              (async () => {
                await dispatch(
                  PostCoupon({
                    cliente_id: customer?.conteudo.id || 0,
                    codigo: coupon,
                    total: cart.tot,
                    unid_med: unitsId || [],
                  })
                );
              })();
            }
            setOpen(false);
          }}
        >
          Validar
        </Button>
      </div>
    </Modal>
  );
}

import { Skeleton } from '@mui/material';
import React from 'react';

export default function LoadingPage() {
  return (
    <>
      <div className="banner">
        <Skeleton
          variant="rectangular"
          height={208}
          style={{ width: '100%' }}
          animation="wave"
        />
      </div>
      <div className="highlights">
        <Skeleton height={28} width={100} />
        <div className="flex gap-4">
          <div>
            <Skeleton
              variant="rectangular"
              height={160}
              width={160}
              animation="wave"
            />
          </div>
          <div>
            <Skeleton
              variant="rectangular"
              height={160}
              width={160}
              animation="wave"
            />
          </div>
          <div>
            <Skeleton
              variant="rectangular"
              height={160}
              width={160}
              animation="wave"
            />
          </div>
          <div>
            <Skeleton
              variant="rectangular"
              height={160}
              width={160}
              animation="wave"
            />
          </div>
          <div>
            <Skeleton
              variant="rectangular"
              height={160}
              width={160}
              animation="wave"
            />
          </div>
        </div>
        <div className="pr-4">
          <Skeleton
            variant="rectangular"
            height={12}
            style={{ width: '100%', marginTop: 16 }}
            animation="wave"
          />
        </div>
      </div>
      <div className="highlights">
        <div className="flex gap-4">
          <div>
            <Skeleton
              variant="rectangular"
              height={36}
              width={95}
              animation="wave"
            />
          </div>
          <div>
            <Skeleton
              variant="rectangular"
              height={36}
              width={95}
              animation="wave"
            />
          </div>
          <div>
            <Skeleton
              variant="rectangular"
              height={36}
              width={95}
              animation="wave"
            />
          </div>
          <div>
            <Skeleton
              variant="rectangular"
              height={36}
              width={95}
              animation="wave"
            />
          </div>
          <div>
            <Skeleton
              variant="rectangular"
              height={36}
              width={95}
              animation="wave"
            />
          </div>
          <div>
            <Skeleton
              variant="rectangular"
              height={36}
              width={95}
              animation="wave"
            />
          </div>
        </div>
      </div>
      <div className="px-4">
        <Skeleton height={28} width={100} />
        <Skeleton
          variant="rectangular"
          height={168}
          style={{ width: '100%', marginBottom: 16 }}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          height={168}
          style={{ width: '100%', marginBottom: 16 }}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          height={168}
          style={{ width: '100%', marginBottom: 16 }}
          animation="wave"
        />
      </div>
    </>
  );
}

import { NextPageContext } from 'next';
import { redirect } from 'next/navigation';

export default async function SEO({ req, res }: NextPageContext) {
	let seo: { token: string } | null = null;
	if (req) {
		let host = req.headers.host;

		if (host) {
			let alias;
			if (host.split('.')[0].includes('kds')) {
				alias = '';
			} else if (host.split('.')[0].includes('cardapio-')) {
				alias = host.split('.')[0].replace('cardapio-', '');
			} else if (host.split('.')[0].includes('cardapio')) {
				alias = host.split('.')[1];
			} else if (host.split('.')[0].includes('www')) {
				alias = host.split('.')[1];
			} else {
				alias = host.split('.')[0];
			}

			if (alias) {
				seo = await fetch(process.env.API_URL + 'comanda/busca-cliente', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'API-TOKEN': process.env.API_TOKEN || '',
					},
					body: JSON.stringify({
						alias,
					}),
				})
					.then(r => r.json())
					.then(r => {
						if (r?.config_cliente?.direcionamento) {
							redirect(r?.config_cliente?.direcionamento);
						}
						return r;
					})
					.catch(error => {
						return null;
					});
			}
		}
	}

	return { props: { seo } };
}

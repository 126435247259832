import { useAppSelector } from '@/redux/hooks';
import React from 'react';

export default function HeaderComp() {
  const { client } = useAppSelector((state) => state.authDelivery);

  return (
    <div>
      <div
        className="bg-cover bg-center h-40 rounded-b-lg"
        style={{
          backgroundImage: `url(${client?.config_cliente.img_fundo})`,
        }}
      ></div>
      <div
        className="w-20 h-20 rounded-full bg-cover bg-center relative ml-6 -mt-10"
        style={{
          backgroundImage: `url(${client?.config_cliente.img_logo})`,
          boxShadow: '0px -1px 6px 8px var(--shadow-color)',
        }}
      ></div>
    </div>
  );
}

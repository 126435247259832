import { useAppSelector } from '@/redux/hooks';
import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button } from '@mui/material';
import ImageNotFound from '@/assets/img/ImageNotFound.png';
import { usePages } from '@/contexts/pages';
import UseProgressiveImage from '@/helpers/progressiveImage';
import { useMain } from '@/contexts/delivery/main';

export default function HighlightsComp() {
  const { listProducts } = useAppSelector((state) => state.menuDelivery);
  const { setOpenProduct } = usePages();
  const { setCurrentProduct } = useMain();

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };

  return (
    <section className="highlights">
      {listProducts && (
        <>
          <h3 className="font-semibold text-lg">Destaques</h3>
          <div>
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={16}
              grabCursor={true}
              modules={[Pagination]}
              pagination={pagination}
            >
              {listProducts.conteudo?.map((highlight) => {
                if (highlight.destaque) {
                  return (
                    <SwiperSlide key={highlight.id} className="highlightsItem">
                      <Button
                        className="highlightsBtn"
                        onClick={() => {
                          setCurrentProduct(highlight);
                          setOpenProduct(true);
                        }}
                      >
                        {highlight.imagens.map((img) => {
                          if (img.principal) {
                            return (
                              <div
                                key={img.ordem}
                                style={{
                                  backgroundImage: `url(${
                                    img.imagem
                                      ? UseProgressiveImage(img.imagem)
                                      : ImageNotFound.src
                                  })`,
                                }}
                                className="highlightsImg"
                              ></div>
                            );
                          }
                        })}
                        <p className="highlightsName">{highlight.nome}</p>
                      </Button>
                    </SwiperSlide>
                  );
                }
              })}
            </Swiper>
          </div>
        </>
      )}
    </section>
  );
}

import { useAppSelector } from '@/redux/hooks';
import { Tab, Tabs } from '@mui/material';
import React from 'react';

type MenuType = {
	currentValue: number | undefined;
	scrollDown: (id: number) => void;
	scrollPosition: number;
};

export default function FixedDepartments({ currentValue, scrollDown, scrollPosition }: MenuType) {
	const { listDepartments } = useAppSelector((state) => state.menuDelivery);

	return (
		<section className={`fixedDepartments  ${scrollPosition > 550 && 'show'}`} style={{ top: '0px' }}>
			{listDepartments && (
				<Tabs
					value={currentValue || (listDepartments.conteudo && listDepartments.conteudo[0].id)}
					variant="scrollable"
					selectionFollowsFocus
					TabIndicatorProps={{ hidden: true }}
					className="tabsList">
					{listDepartments.conteudo?.map((department, index) => (
						<Tab
							key={department.id}
							value={department.id}
							label={department.nome}
							className={`customTab ${department.id === currentValue && 'active'}`}
							onClick={() => scrollDown(department.id)}
						/>
					))}
				</Tabs>
			)}
		</section>
	);
}

import { useAppSelector } from '@/redux/hooks';
import { Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ListProductsResponse } from '@/models/listProducts.response';
import ProductItem from '@/components/ProductItem';
import { useMain } from '@/contexts/delivery/main';

type ProductsType = {
	currentValue: number | undefined;
	scrollDown: (id: number) => void;
};

type ListType = {
	id: number;
	name: string;
	products: ListProductsResponse['conteudo'];
}[];

export default function ProductsComp({ currentValue, scrollDown }: ProductsType) {
	const { setCurrentProduct } = useMain();
	const { client } = useAppSelector((state) => state.authDelivery);
	const { order } = useAppSelector((state) => state.home);
	const { listProducts, listDepartments } = useAppSelector((state) => state.menuDelivery);
	const [data, setData] = useState<ListType>([]);

	useEffect(() => {
		if (listProducts && listDepartments) {
			if (listProducts.retorno && listDepartments.retorno) {
				const newData: ListType = [];
				listDepartments.conteudo?.forEach((department, index) => {
					newData.push({
						name: department.nome,
						id: department.id,
						products: [],
					});

					listProducts.conteudo?.forEach((product) => {
						if (department.id === product.departamento_id) {
							newData[index].products.push({
								...product,
							});
						}
					});
					switch (order) {
						case 'DEF':
							break;
						case 'NASC':
							newData[index].products = newData[index].products.sort((productOne, productTwo) => {
								return productOne.nome.toLowerCase().localeCompare(productTwo.nome.toLowerCase());
							});
							break;
						case 'NDESC':
							newData[index].products = newData[index].products.sort((productOne, productTwo) => {
								return productTwo.nome.toLowerCase().localeCompare(productOne.nome.toLowerCase());
							});
							break;
						case 'PASC':
							newData[index].products = newData[index].products.sort((productOne, productTwo) => {
								const priceOne = productOne.preco_principal.preco_promocao ? productOne.preco_principal.preco_promocao : productOne.preco_principal.preco;
								const priceTwo = productTwo.preco_principal.preco_promocao ? productTwo.preco_principal.preco_promocao : productTwo.preco_principal.preco;
								return priceOne - priceTwo;
							});
							break;
						case 'PDESC':
							newData[index].products = newData[index].products.sort((productOne, productTwo) => {
								const priceOne = productOne.preco_principal.preco_promocao ? productOne.preco_principal.preco_promocao : productOne.preco_principal.preco;
								const priceTwo = productTwo.preco_principal.preco_promocao ? productTwo.preco_principal.preco_promocao : productTwo.preco_principal.preco;
								return priceTwo - priceOne;
							});
							break;
						default:
							break;
					}
				});

				setData(newData);
			}
		}
	}, [listProducts, listDepartments, order]);

	return (
		<section className="products">
			{listDepartments && (
				<Tabs
					value={currentValue || (listDepartments.conteudo && listDepartments.conteudo[0].id)}
					variant="scrollable"
					selectionFollowsFocus
					TabIndicatorProps={{ hidden: true }}
					className="tabsList">
					{listDepartments.conteudo?.map((department, index) => (
						<Tab
							key={department.id}
							value={department.id}
							label={department.nome}
							className={`customTab ${department.id === currentValue && 'active'}`}
							onClick={() => scrollDown(department.id)}
						/>
					))}
				</Tabs>
			)}

			{data.length > 0 && (
				<div className="p-4 pb-0">
					{data.map((item) => (
						<div key={item.id} id={item.id.toString()} className="productContainer">
							<p className="productDepartment">{item.name}</p>
							<div>
								{item.products.map((product) => (
									<ProductItem product={product} key={product.id} setCurrentProduct={setCurrentProduct} client={client} />
								))}
							</div>
						</div>
					))}
				</div>
			)}
		</section>
	);
}

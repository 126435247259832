import React, { useEffect, useState } from 'react';
import HeaderComp from '../Header';
import ModalError from '@/components/ModalError';
import { GetStores } from '@/services/delivery/auth';
import 'dayjs/locale/pt-br';
import { KeyboardArrowDown, SearchOutlined } from '@mui/icons-material';
import CommonMask from '@/helpers/masks';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { Button, TextField } from '@mui/material';
import CompaniesList from '../CompaniesList';
import ModalLoading from '@/components/ModalLoading';

function getWindowDimensions() {
  const { innerHeight: height } = window;
  return height;
}

function SearchCompanyPage() {
  const dispatch = useAppDispatch();
  const { requestError, listStores } = useAppSelector(
    (state) => state.authDelivery
  );
  const [vh, setVh] = useState(0);
  const [showSnack, setShowSnack] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [openCompaniesList, setOpenCompaniesList] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setVh(getWindowDimensions());
  }, []);

  useEffect(() => {
    if (requestError) {
      setOpenLoading(false);
      setSnackMsg(requestError);
      setShowSnack(true);
    }
  }, [requestError]);

  async function getListStores({
    cep,
    lat,
    long,
  }: {
    cep?: string;
    lat?: string;
    long?: string;
  }) {
    await dispatch(
      GetStores({ cep: cep || '', latitude: lat || '', longitude: long || '' })
    );
  }

  useEffect(() => {
    if (listStores) {
      setOpenLoading(false);
      if (listStores.conteudo) {
        setOpenCompaniesList(true);
      } else {
        setSnackMsg('Nenhuma unidade encontrada para o CEP buscado!');
        setShowSnack(true);
      }
    }
  }, [listStores]);

  async function submitSearch() {
    await setOpenLoading(true);
    await getListStores({ cep: search });
  }

  return (
    <section
      style={{
        minHeight: vh,
      }}
      id="searchCompany"
    >
      <HeaderComp />

      <div className="container">
        <h4
          className="call"
          style={{
            color: 'var(--first-color)',
          }}
        >
          Faça seu pedido na unidade com entrega mais barata e próxima de você
        </h4>
        <p className="subCall">
          Precisamos apenas que informe seu endereço para verificarmos se
          atendemos sua região.
        </p>

        <div>
          <Button
            variant="contained"
            className="getLocation"
            onClick={() => {
              setOpenLoading(true);
              navigator.geolocation.getCurrentPosition(function (position) {
                getListStores({
                  lat: position.coords.latitude.toString(),
                  long: position.coords.longitude.toString(),
                });
              });
            }}
          >
            USAR MINHA LOCALIZAÇÃO ATUAL
          </Button>
          <div className="searchBlock">
            <TextField
              fullWidth
              label="Insira o cep do endereço..."
              value={CommonMask.cep(search)}
              variant="outlined"
              onChange={(event) => {
                if (event.target.value.length < 10) {
                  setSearch(event.currentTarget.value);
                }
              }}
              onSubmit={() => {
                submitSearch();
              }}
              inputMode="numeric"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
            <Button
              className="btnSearch"
              onClick={() => {
                submitSearch();
              }}
            >
              <SearchOutlined />
            </Button>
          </div>
          <div className="flex items-center justify-center mt-5 gap-1 mb-2">
            <p
              className="uppercase text-xs"
              style={{
                color: 'var(--second-color)',
              }}
            >
              Não sabe seu CEP?
            </p>
            <a
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
              className="text-xs font-bold"
              style={{
                color: 'var(--second-color)',
              }}
            >
              Clique aqui
            </a>
          </div>
        </div>
      </div>

      <footer className="footer">
        <Button
          variant="text"
          className="btnAll"
          onClick={() => {
            (async () => {
              await setOpenLoading(true);
              await getListStores({});
            })();
          }}
        >
          <p className="btnAllTxt">Ver todas nossas unidades</p>
          <KeyboardArrowDown
            style={{
              color: 'var(--first-color)',
            }}
          />
        </Button>
      </footer>
      <CompaniesList setOpen={setOpenCompaniesList} open={openCompaniesList} />
      <ModalLoading open={openLoading} txt="Carregando unidades..." />
      <ModalError open={showSnack} setOpen={setShowSnack} errorMsg={snackMsg} />
    </section>
  );
}

export default SearchCompanyPage;

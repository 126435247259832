import CheckIcon from '@/assets/svg/Check';
import { usePages } from '@/contexts/pages';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { CircularProgress, Dialog, DialogContent, Slide, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import CommonMask from '@/helpers/masks';
import CommonClears from '@/helpers/clears/common.clear';
import CommonValidators from '@/helpers/validators/common.validators';
import { ListOrder, StatusOrder } from '@/services/delivery/order';
import ModalError from '@/components/ModalError';
import ModalLoading from '@/components/ModalLoading';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderPage() {
	const dispatch = useAppDispatch();
	const { openOrder, setOpenOrder } = usePages();
	const { requestError, loading, listOrders, orderStatus } = useAppSelector((state) => state.orderDelivery);
	const { client } = useAppSelector((state) => state.authDelivery);
	const [showError, setShowError] = useState(false);
	const [openLoading, setOpenLoading] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');
	const [cpf, setCpf] = useState('');
	const [telefone, setTelefone] = useState('');
	const [error, setError] = useState({
		cpf: '',
		telefone: '',
	});
	const [requestInterval, setRequestInterval] = useState<any>(null);

	function onDismiss() {
		clearInterval(requestInterval);
		setOpenOrder(false);
	}

	async function getStatus() {
		if (!requestInterval) {
			const interval = setInterval(async function () {
				if (listOrders && listOrders.conteudo) {
					await dispatch(StatusOrder({ cpf: listOrders.conteudo[0].cpf || '', phone: listOrders.conteudo[0].fone || ''}));
				}
			}, 5000);
			setRequestInterval(interval);
		}
	}

	useEffect(() => {
		if (requestError) {
			setSnackMsg(requestError);
			setShowError(true);
		}
	}, [requestError]);

	async function getOrder() {
		setOpenLoading(true);
		client?.config_cliente.pedir_cpf_delivery ? await dispatch(ListOrder(cpf)) : await dispatch(ListOrder(telefone));
	}

	useEffect(() => {
		if ((openOrder && cpf && client?.config_cliente.pedir_cpf_delivery) || (openOrder && telefone && !client?.config_cliente.pedir_cpf_delivery)) {
			getOrder();
		}
	}, [openOrder]);

	useEffect(() => {
		if (openOrder) {
			if (listOrders && listOrders.conteudo && listOrders.conteudo[0].status !== 'E') {
				getStatus();
			}
		} else {
			clearInterval(requestInterval);
			setRequestInterval(null);
		}
	}, [openOrder, listOrders]);

	useEffect(() => {
		if (orderStatus?.status === 'E') {
			clearInterval(requestInterval);
		}
	}, [orderStatus]);

	useEffect(() => {
		if (!loading) {
			setOpenLoading(false);
		}
	}, [loading]);

	useEffect(() => {
		if (!client?.config_cliente.pedir_cpf_delivery) {
			if (telefone.length === 11) {
				const telefoneValidator = CommonValidators.isCellphoneValid(telefone);

				setError((prev) => ({
					cpf: prev.cpf,
					telefone: telefoneValidator.error,
				}));

				if (telefoneValidator.status) {
					getOrder();
				}
			} else {
				setError((prev) => ({
					cpf: prev.cpf,
					telefone: '',
				}));
			}
		}
	}, [telefone]);

	useEffect(() => {
		if (client?.config_cliente.pedir_cpf_delivery) {
			if (cpf.length === 11) {
				const cpfValidator = CommonValidators.isCPFValid(cpf);

				setError((prev) => ({
					cpf: cpfValidator.error,
					telefone: prev.telefone,
				}));

				if (cpfValidator.status) {
					getOrder();
				}
			} else {
				setError((prev) => ({
					cpf: '',
					telefone: prev.telefone,
				}));
			}
		}
	}, [cpf]);

	return (
		<Dialog fullScreen open={openOrder} onClose={onDismiss} TransitionComponent={Transition} id="successPage" scroll="paper" className="customDialog" disableScrollLock>
			<HeaderBottomSheet title="Status do pedido" onDismiss={onDismiss} />
			<DialogContent>
				{listOrders?.retorno && listOrders.conteudo ? (
					<section className="container">
						<div className="header">
							<div className="icon">
								<CheckIcon />
							</div>
							<h1 className="title">Pedido realizado com sucesso!</h1>
						</div>
						{loading ? (
							<div className="loadingDefault">
								<CircularProgress className="loading" />
							</div>
						) : (
							<>
								<div>
									{!listOrders.conteudo[0].agendamento && (
										<>
											<p
												className="mb-1 text-sm font-medium"
												style={{
													color: 'var(--first-color)',
												}}>
												{listOrders.conteudo[0].titulo_pedido}
											</p>
											<p
												className="mb-4 text-2xl font-medium"
												style={{
													color: 'var(--first-color)',
												}}>
												{listOrders.conteudo[0].previsao_entrega}
											</p>
											<div className="flex gap-1 mb-4">
												<div
													className="rounded-full h-2"
													style={{
														flex: 1,
														backgroundColor:
															orderStatus?.status === 'C' ||
															listOrders.conteudo[0].status === 'C' ||
															orderStatus?.status === 'F' ||
															listOrders.conteudo[0].status === 'F' ||
															orderStatus?.status === 'E' ||
															listOrders.conteudo[0].status === 'E'
																? 'var(--main-color)'
																: '#999',
													}}
												/>
												<div
													className="rounded-full h-2"
													style={{
														flex: 2,
														backgroundColor:
															orderStatus?.status === 'F' || listOrders.conteudo[0].status === 'F' || orderStatus?.status === 'E' || listOrders.conteudo[0].status === 'E'
																? 'var(--main-color)'
																: '#999',
													}}
												/>
												<div
													className="rounded-full h-2"
													style={{
														flex: 1,
														backgroundColor: orderStatus?.status === 'E' || listOrders.conteudo[0].status === 'E' ? 'var(--main-color)' : '#999',
													}}
												/>
											</div>
											<div className="flex gap-2 mb-6">
												<div
													className="w-4 h-4 rounded-full"
													style={{
														backgroundColor: 'var(--main-color)',
													}}
												/>
												<p
													className="mb-1 text-sm font-medium"
													style={{
														color: 'var(--first-color)',
													}}>
													{orderStatus?.retorno ? orderStatus.descricao : listOrders.conteudo[0].status_descricao}
												</p>
											</div>
										</>
									)}
									{listOrders.conteudo[0].agendamento && (
										<p
											className="mb-5 text-lg text-center"
											style={{
												color: 'var(--first-color)',
											}}>
											{listOrders.conteudo[0].agendamento}
										</p>
									)}
								</div>
								<div
									className="p-4"
									style={{
										borderColor: '#C3C0C0',
										borderWidth: 1,
										boxShadow: '0 4px 4px 0 #00000040',
									}}>
									<p
										className="mb-3 text-sm font-medium"
										style={{
											color: 'var(--first-color)',
										}}>
										{listOrders.conteudo[0].retirada ? <>Retirada em</> : <>Entrega em</>}
									</p>
									<p
										className="mb-5 text-sm"
										style={{
											color: 'var(--first-color)',
										}}>
										<>
											{listOrders.conteudo[0].endereco}
											{', ' + listOrders.conteudo[0].numero}
											{listOrders.conteudo[0].complemento && ', ' + listOrders.conteudo[0].complemento}
											{', ' + listOrders.conteudo[0].bairro}
											{', ' + listOrders.conteudo[0].cidade}
											{', ' + listOrders.conteudo[0].uf}
										</>
									</p>

									<p
										className="text-sm font-medium"
										style={{
											color: 'var(--first-color)',
										}}>
										Detalhes do pedido
									</p>
									{listOrders.conteudo[0].produtos.map((product) => (
										<div key={product.id} className="mt-3 flex items-center gap-2">
											<div className="w-14 h-14 bg-cover bg-center rounded-md" style={{ backgroundImage: `url(${product.imagem})` }}></div>
											<div className="flex-1">
												<p
													style={{
														color: 'var(--first-color)',
													}}>
													{product.nome}
												</p>
												{product.adicionais?.length ? (
													<p
														className="text-xs"
														style={{
															color: 'var(--first-color)',
														}}>
														(
														{product.adicionais.map((option, key) => (
															<span key={option.id}>{(option.quantidade > 1 ? option.quantidade + ' - ' : '') + option.nome}</span>
														))}
														)
													</p>
												) : (
													<></>
												)}
											</div>
											<p className="font-semibold" style={{ color: 'var(--first-color)' }}>
												<span>R$:</span> {CommonMask.currency(product.total.toFixed(2).toString())}
											</p>
										</div>
									))}
									<div
										className="mt-3 pt-3"
										style={{
											borderColor: '#C3C0C0',
											borderTopWidth: 1,
										}}>
										{!listOrders.conteudo[0].retirada ? (
											<div className="flex justify-between items-center mb-1">
												<p
													className="text-sm"
													style={{
														color: 'var(--second-color)',
													}}>
													Taxa de entrega:
												</p>
												<p className="text-sm" style={{ color: 'var(--second-color)' }}>
													{listOrders.conteudo[0].valor_entrega ? (
														<>
															<span>R$:</span> {CommonMask.currency(listOrders.conteudo[0].valor_entrega.toFixed(2).toString())}
														</>
													) : (
														<>Frete grátis</>
													)}
												</p>
											</div>
										) : (
											<></>
										)}

										{listOrders.conteudo[0].cupom ? (
											<div className="flex justify-between items-center mb-1">
												<p
													className="text-sm"
													style={{
														color: 'var(--second-color)',
													}}>
													Cupom aplicado:
												</p>
												<p className="text-sm" style={{ color: 'var(--second-color)' }}>
													{listOrders.conteudo[0].cupom}
													{listOrders.conteudo[0].total_desconto && listOrders.conteudo[0].tipo_desconto === 'P'
														? `: - ${listOrders.conteudo[0].total_desconto}%`
														: `: - R$ ${CommonMask.currency(listOrders.conteudo[0].total_desconto.toFixed(2).toString())}`}
												</p>
											</div>
										) : (
											<></>
										)}
										<div className="flex justify-between items-center">
											<p
												className="font-medium"
												style={{
													color: 'var(--first-color)',
												}}>
												Total:
											</p>
											<p className="text-lg font-semibold" style={{ color: 'var(--first-color)' }}>
												<span>R$:</span> {CommonMask.currency(listOrders.conteudo[0].total.toFixed(2).toString())}
											</p>
										</div>
									</div>
								</div>
							</>
						)}
					</section>
				) : (
					<section className="container">
						<p
							className="mb-4 text-lg text-center font-medium"
							style={{
								color: 'var(--first-color)',
							}}>
							Insira seu {client?.config_cliente.pedir_cpf_delivery ? 'CPF' : 'Telefone'} para vizualizar seu pedido!
						</p>
						{client?.config_cliente.pedir_cpf_delivery && (
							<TextField
								value={CommonMask.cpf(cpf)}
								fullWidth
								error={!!error.cpf}
								helperText={error.cpf}
								className="mb-3"
								label="Informe seu CPF"
								inputMode="numeric"
								required
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								variant="outlined"
								disabled={loading}
								onChange={(event) => {
									if (event.target.value.length <= 14) {
										const value = CommonClears.clearCPF(event.target.value);
										setCpf(value);
									}
								}}
							/>
						)}
						{!client?.config_cliente.pedir_cpf_delivery && (
							<TextField
								value={CommonMask.phone(telefone)}
								fullWidth
								error={!!error.telefone}
								helperText={error.telefone}
								className="mb-3"
								label="Informe seu Telefone"
								inputMode="numeric"
								required
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								variant="outlined"
								disabled={loading}
								onChange={(event) => {
									if (event.target.value.length <= 15) {
										const value = CommonClears.clearPhone(event.target.value);
										setTelefone(value);
									}
								}}
							/>
						)}
					</section>
				)}
			</DialogContent>

			<ModalLoading open={openLoading} txt="Buscando Pedido..." />
			<ModalError errorMsg={snackMsg} open={showError} setOpen={setShowError} />
		</Dialog>
	);
}

import CommonClears from '@/helpers/clears/common.clear';
import CommonMask from '@/helpers/masks';
import { ClientModel } from '@/models/delivery/clientModel';
import { useAppSelector } from '@/redux/hooks';
import { TextField } from '@mui/material';
import React from 'react';

type FormType = {
	handleChange(value: string | number, fieldName: string, typeForm: 'personal' | 'card'): void;
	form: ClientModel;
	error: ClientModel;
};

export default function PersonalData({ handleChange, form, error }: FormType) {
	const { loading } = useAppSelector((state) => state.payment);
	const { client } = useAppSelector((state) => state.authDelivery);

	return (
		<section className="personalData">
			{client?.config_cliente.pedir_cpf_delivery && (
				<TextField
					value={CommonMask.cpf(form.cpf)}
					fullWidth
					error={!!error.cpf}
					helperText={error.cpf}
					label="Informe seu CPF"
					inputMode="numeric"
					className="input"
					required
					inputProps={{
						inputMode: 'numeric',
						pattern: '[0-9]*',
					}}
					variant="outlined"
					disabled={loading}
					onChange={(event) => {
						if (event.target.value.length <= 14) {
							const value = CommonClears.clearCPF(event.target.value);
							handleChange(value, 'cpf', 'personal');
						}
					}}
				/>
			)}

			<TextField
				value={CommonMask.phone(form.phone)}
				required
				error={!!error.phone}
				helperText={error.phone}
				className="input"
				fullWidth
				label="Seu telefone"
				InputLabelProps={form.phone ? { shrink: true } : {}}
				inputMode="numeric"
				inputProps={{
					inputMode: 'numeric',
					pattern: '[0-9]*',
				}}
				variant="outlined"
				disabled={loading}
				onChange={(event) => {
					if (event.target.value.length <= 15) {
						const value = CommonClears.clearPhone(event.target.value);
						handleChange(value, 'phone', 'personal');
					}
				}}
			/>

			<TextField
				value={form.name}
				required
				error={!!error.name}
				helperText={error.name}
				className="input"
				fullWidth
				label="Nome completo"
				variant="outlined"
				type="text"
				inputProps={{MaxLength:25}}
				disabled={loading}
				onChange={(event) => {
					const value = event.target.value;
					handleChange(value, 'name', 'personal');
				}}
			/>

			<p className="desc">Para realizar seu pedido vamos precisar de suas informações, este é um ambiente protegido.</p>
		</section>
	);
}
